<template>
  <div>
    <b-card>
      <b-alert
        :show="invalidForm"
        variant="warning"
        class="p-2 border-secondary d-flex align-items-center mb-2"
      >
        <feather-icon icon="AlertTriangleIcon" size="18" class="mr-1 text-dark" />
        <span class="text-dark">
          <strong> Atenção. </strong> Preencha as informações abaixo para cadastrar uma nova
          emissão.
        </span>
      </b-alert>
      <div class="d-flex mb-2">
        <feather-icon icon="FileIcon" size="26" class="text-secondary mr-2" />
        <h2 class="mb-0">Informações básicas</h2>
      </div>
      <b-form @submit.prevent="onSubmit">
        <ValidationObserver ref="operationForm">
          <section>
            <h3>Emissão</h3>
            <b-row>
              <b-col cols="4">
                <validation-provider #default="{ errors }" name="Nome" rules="required">
                  <b-form-group label="Nome" :invalid-feedback="errors[0]">
                    <b-form-input
                      v-model="operation.nome"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider #default="{ errors }" name="ID Externo" rules="required">
                  <b-form-group label="ID" :invalid-feedback="errors[0]">
                    <b-form-input
                      v-model="operation.id_externo"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider #default="{ errors }" name="Tipo de título" rules="required">
                  <b-form-group label="Tipo de título" :invalid-feedback="errors[0]">
                    <v-select
                      v-model="operation.tipo_titulo"
                      :options="tipos"
                      :clearable="false"
                      class="assignor-selector d-flex flex-column"
                      :reduce="(option) => option.value"
                      :class="{ 'invalid-selection': errors[0] }"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Valor inicial"
                  rules="required|min_value:0"
                >
                  <b-form-group label="Valor Inicial" :invalid-feedback="errors[0]">
                    <money
                      v-model="operation.valor_inicial"
                      class="form-control"
                      :class="{ 'is-invalid': errors.length > 0 }"
                      v-bind="money"
                      :maxlength="17"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Performance mínima"
                  rules="required"
                >
                  <b-form-group label="Performance mínima" :invalid-feedback="errors[0]">
                    <money
                      v-model="operation.performance_minima"
                      class="form-control percentage"
                      :class="{ 'is-invalid': errors.length > 0 }"
                      v-bind="percentage"
                      :maxlength="8"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider
                  #default="{ errors }"
                  name="Percentual máximo de desenquadramento"
                  rules="required"
                >
                  <b-form-group
                    label="Percentual máximo de desenquadramento"
                    :invalid-feedback="errors[0]"
                  >
                    <money
                      v-model="operation.desenquadramento_maximo"
                      :state="errors.length > 0 ? false : null"
                      class="form-control percentage"
                      :class="{ 'is-invalid': errors.length > 0 }"
                      v-bind="percentage"
                      :maxlength="8"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="4">
                <validation-provider #default="{ errors }" name="Tipo de veículo " rules="required">
                  <b-form-group label="Tipo de veículo" :invalid-feedback="errors[0]">
                    <v-select
                      v-model="operation.tipo_veiculo"
                      :options="tiposVeiculos"
                      :clearable="false"
                      class="assignor-selector d-flex flex-column"
                      :reduce="(option) => option.value"
                      :class="{ 'invalid-selection': errors[0] }"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </section>
          <section>
            <h3>Cedente</h3>
            <b-row>
              <b-col cols="4">
                <validation-provider #default="{ errors }" name="Cedente" rules="required">
                  <b-form-group label="Nome" :invalid-feedback="errors[0]">
                    <v-select
                      v-model="operation.cedente_id"
                      :options="assignors"
                      :clearable="false"
                      class="assignor-selector d-flex flex-column"
                      :reduce="(option) => option.value"
                      :class="{ 'invalid-selection': errors[0] }"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider
                  #default="{ errors }"
                  name="Banco"
                  vid="banco_cedente"
                  rules="required"
                >
                  <b-form-group
                    class="label-text-nowrap"
                    label="Banco"
                    :invalid-feedback="errors[0]"
                  >
                    <v-select
                      id="banco_cedente"
                      v-model="operation.banco_conta_operacao_id"
                      :options="bancos"
                      :clearable="false"
                      class="assignor-selector d-flex flex-column"
                      :reduce="(option) => option.value"
                      :class="{ 'invalid-selection': errors[0] }"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider
                  #default="{ errors }"
                  name="Agência"
                  vid="agencia_cedente"
                  rules="required"
                >
                  <b-form-group label="Agência" :invalid-feedback="errors[0]">
                    <b-form-input
                      id="agencia_cedente"
                      v-model="operation.agencia_conta_operacao"
                      :state="errors.length > 0 ? false : null"
                      type="tel"
                      v-mask="'####'"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider
                  #default="{ errors }"
                  name="Conta"
                  vid="conta_cedente"
                  rules="required"
                >
                  <b-form-group label="Conta" :invalid-feedback="errors[0]">
                    <b-form-input
                      id="conta_cedente"
                      v-model="operation.numero_conta_operacao"
                      :state="errors.length > 0 ? false : null"
                      type="tel"
                      v-mask="'############'"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider
                  #default="{ errors }"
                  name="Dígito"
                  vid="digito_cedente"
                  rules="required"
                >
                  <b-form-group label="Dígito" :invalid-feedback="errors[0]">
                    <b-form-input
                      id="conta_cedente"
                      v-model="operation.digito_conta_operacao"
                      :state="errors.length > 0 ? false : null"
                      type="tel"
                      v-mask="'#'"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </section>
          <section>
            <h3>Financiador</h3>
            <b-row>
              <b-col cols="4">
                <validation-provider #default="{ errors }" name="Financiador" rules="required">
                  <b-form-group label="Nome" :invalid-feedback="errors[0]">
                    <v-select
                      v-model="operation.financiador_id"
                      :options="financiers"
                      :clearable="false"
                      class="assignor-selector d-flex flex-column"
                      :reduce="(option) => option.value"
                      :class="{ 'invalid-selection': errors[0] }"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider #default="{ errors }" name="Banco" rules="required">
                  <b-form-group
                    class="label-text-nowrap"
                    label="Banco"
                    :invalid-feedback="errors[0]"
                  >
                    <v-select
                      v-model="operation.banco_conta_liquidacao_id"
                      :options="bancos"
                      :clearable="false"
                      class="assignor-selector d-flex flex-column"
                      :reduce="(option) => option.value"
                      :class="{ 'invalid-selection': errors[0] }"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider #default="{ errors }" name="Agência" rules="required">
                  <b-form-group label="Agência" :invalid-feedback="errors[0]">
                    <b-form-input
                      v-model="operation.agencia_conta_liquidacao"
                      :state="errors.length > 0 ? false : null"
                      type="tel"
                      v-mask="'####'"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider #default="{ errors }" name="Conta" rules="required">
                  <b-form-group label="Conta" :invalid-feedback="errors[0]">
                    <b-form-input
                      v-model="operation.numero_conta_liquidacao"
                      :state="errors.length > 0 ? false : null"
                      type="tel"
                      v-mask="'############'"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider #default="{ errors }" name="Dígito" rules="required">
                  <b-form-group label="Dígito" :invalid-feedback="errors[0]">
                    <b-form-input
                      v-model="operation.digito_conta_liquidacao"
                      :state="errors.length > 0 ? false : null"
                      type="tel"
                      v-mask="'#'"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </section>
          <hr />
          <div class="d-flex mb-2 mt-2">
            <feather-icon icon="SettingsIcon" size="26" class="text-secondary mr-2" />
            <h2 class="mb-0">Configurações Gerais</h2>
          </div>
          <section>
            <b-row>
              <b-col cols="2">
                <b-form-group label="Operar somente em dias úteis?" class="d-flex justify-content-center">
                  <b-form-checkbox v-model="operation.somente_dia_util" />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="Hora limite para operação">
                  <input type="time" class="form-control" v-model="operation.hora_limite">
                </b-form-group>
              </b-col>
            </b-row>
          </section>
          <hr />
          <div class="d-flex mb-2 mt-2">
            <feather-icon icon="ListIcon" size="26" class="text-secondary mr-2" />
            <h2 class="mb-0">Critérios de elegibilidade</h2>
          </div>
          <section>
            <b-row>
              <b-col cols="2">
                <validation-provider #default="{ errors }" name="Taxa minima" rules="required">
                  <b-form-group label="Taxa mínima operação" :invalid-feedback="errors[0]">
                    <money
                      v-model="operation.taxa_minima"
                      :state="errors.length > 0 ? false : null"
                      class="form-control percentage"
                      :class="{ 'is-invalid': errors.length > 0 }"
                      v-bind="percentage"
                      :maxlength="8"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                  name="Concentração máxima"
                  rules="required"
                >
                  <b-form-group
                    label="Concentração máxima por sacado"
                    :invalid-feedback="errors[0]"
                  >
                    <money
                      v-model="operation.concentracao_maxima_por_sacado"
                      :state="errors.length > 0 ? false : null"
                      class="form-control percentage"
                      :class="{ 'is-invalid': errors.length > 0 }"
                      v-bind="percentage"
                      :maxlength="8"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider #default="{ errors }" name="Prazo mínimo" rules="required">
                  <b-form-group label="Prazo mínimo" :invalid-feedback="errors[0]">
                    <b-form-input
                      v-model="operation.prazo_minimo"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Em dias"
                      type="number"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="5" class="d-flex justify-content-end align-items-end">
                <validation-provider #default="{ errors }" name="Prazo" rules="required">
                  <b-form-group label="" :invalid-feedback="errors[0]">
                    <div class="d-flex justify-content-lg-around align-items-center text-nowrap">
                      <span class="mr-50"> Bloquear sacados com </span>
                      <b-form-input
                        v-model="operation.prazo_bloqueio_sacado"
                        class="w-75px"
                        type="number"
                      />
                      <span class="ml-50"> dias em atraso </span>
                    </div>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </section>
          <hr />
          <div class="d-flex mb-2 mt-2">
            <feather-icon icon="FileIcon" size="26" class="text-secondary mr-2" />
            <h2 class="mb-0">Regras de desenquadramento</h2>
          </div>
          <section>
            <div class="d-flex justify-content-between align-items-end">
              <div>
                <b-form-checkbox v-model="operation.desenquadrar_titulos_cancelados" class="mb-1">
                  Títulos cancelados
                </b-form-checkbox>
                <b-form-checkbox
                  :checked="true"
                  v-model="operation.desenquadrar_alongamento_prazo"
                  class="mb-1"
                >
                  Alongamento de prazo - Aplicar percentual
                </b-form-checkbox>
                <b-form-checkbox
                  :checked="true"
                  v-model="operation.desenquadrar_reducao_valor"
                  class="mb-1"
                >
                  Redução de valor
                </b-form-checkbox>
              </div>
              <div class="d-flex justify-content-lg-around align-items-center text-nowrap">
                <span class="mr-50"> Desenquadrar após </span>
                <b-form-input
                  v-model="operation.prazo_desenquadramento"
                  class="w-75px"
                  type="number"
                />
                <span class="ml-50"> dias em atraso </span>
              </div>
            </div>
          </section>
        </ValidationObserver>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BCard,
  BAlert,
  BButton,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'OperationNew',
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BAlert,
    BButton,
    vSelect,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      invalidForm: false,
      operation: {
        nome: '',
        id_externo: '',
        tipo_titulo: '',
        tipo_veiculo: '',
        valor_inicial: '',
        performance_minima: '',
        desenquadramento_maximo: '',
        cedente_id: '',
        financiador_id: '',
        banco_conta_operacao_id: '',
        agencia_conta_operacao: '',
        numero_conta_operacao: '',
        digito_conta_operacao: '',
        banco_conta_liquidacao_id: '',
        agencia_conta_liquidacao: '',
        numero_conta_liquidacao: '',
        digito_conta_liquidacao: '',
        taxa_minima: '',
        concentracao_maxima_por_sacado: '',
        prazo_minimo: '',
        desenquadrar_titulos_cancelados: false,
        desenquadrar_alongamento_prazo: false,
        desenquadrar_reducao_valor: false,
        prazo_desenquadramento: '0',
        prazo_bloqueio_sacado: '0',
      },
      tipos: [
        {
          value: 'nfe',
          label: 'NFE',
        },
      ],
      tiposVeiculos: [
        {
          value: 'debenture',
          label: 'Debênture',
        },
        {
          value: 'fdic',
          label: 'FDIC',
        },
        {
          value: 'caixa_proprio',
          label: 'Caixa proprio',
        },
      ],
      bancos: [
        {
          value: 1,
          label: 'Banco 1',
        },
      ],
      assignors: [],
      financiers: [],
      money: {
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
      },
      percentage: {
        suffix: '%',
        prefix: '',
        precision: 4,
      },
    }
  },
  mounted() {
    this.getAssignors()
    this.getFinanciers()
    this.getBanks()

    if (this.$route.params.id) {
      this.getOperation()
    }
  },
  methods: {
    async getOperation() {
      const data = await this.$store.dispatch('admin/getOperation', this.$route.params.id)
      this.operation = { ...data }
      this.operation.performance_minima = parseFloat(this.operation.performance_minima)
      this.operation.desenquadramento_maximo = parseFloat(this.operation.desenquadramento_maximo)
      this.operation.taxa_minima = parseFloat(this.operation.taxa_minima)
      this.operation.concentracao_maxima_por_sacado = parseFloat(
        this.operation.concentracao_maxima_por_sacado,
      )
    },
    async getBanks() {
      const { data } = await this.$store.dispatch('app/getBanks')
      this.bancos = data.map((item) => {
        return { value: item.id, label: item.nome }
      })
    },
    async getAssignors() {
      const data = await this.$store.dispatch('admin/getAssignors')
      this.assignors = data.map((item) => {
        return { value: item.id, label: item.nome }
      })
    },
    async getFinanciers() {
      const data = await this.$store.dispatch('admin/getFinanciers')
      this.financiers = data.map((item) => {
        return { value: item.id, label: item.nome }
      })
    },
    async onSubmit() {
      const success = await this.$refs.operationForm.validate()
      if (success) {
        this.invalidForm = false
        return this.saveOperation()
      } else {
        this.invalidForm = true
      }
    },
    async saveOperation() {
      this.$swal({
        title: 'Confirmação',
        text: 'Deseja realmente salvar essa emissão?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.value) {
          const action = this.$route.params.id ? 'admin/updateOperation' : 'admin/saveOperation'
          const data = await this.$store.dispatch(action, this.operation)
          if (data) {
            this.$swal({
              title: 'Sucesso',
              text: 'Emissão salva com sucesso!',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              this.$router.push({ name: 'emissions.index' })
            })
          }
        }
      })
    },
  },
  computed: {},
}
</script>

<style lang="scss">
.label-text-nowrap {
  legend {
    white-space: nowrap;
  }
}

.w-75px {
  width: 75px;
}

.invalid-selection .vs__dropdown-toggle {
  border-color: #fc065e;
}
</style>
